


































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Partner extends Vue {
	private parentData = {
		titleName: "客户展示",
		dec: "1000+ 合作服务企业",
		bannerImg: require("../../assets/images/banner2.png")
	};

	private list = [
		{
			img: require("../../assets/images/partner/partner1.png"),
			name: "中国移动"
		},
		{
			img: require("../../assets/images/partner/partner2.png"),
			name: "中国联通"
		},
		{
			img: require("../../assets/images/partner/partner3.png"),
			name: "中国电信"
		}
	];

	private serveList = [
		{
			img: require("../../assets/images/partner/partner4.png"),
			name: "中国石化"
		},
		{
			img: require("../../assets/images/partner/partner5.png"),
			name: "中国工商银行"
		},
		{
			img: require("../../assets/images/partner/partner6.png"),
			name: "中国石油"
		},
		{
			img: require("../../assets/images/partner/partner7.png"),
			name: "保利·地产"
		},
		{
			img: require("../../assets/images/partner/partner8.png"),
			name: "中国邮政"
		},
		{
			img: require("../../assets/images/partner/partner9.png"),
			name: "中粮"
		},
		{
			img: require("../../assets/images/partner/partner10.png"),
			name: "中国建设银行"
		},
		{
			img: require("../../assets/images/partner/partner11.png"),
			name: "链家"
		},
		{
			img: require("../../assets/images/partner/partner12.png"),
			name: "中国民生银行"
		},
		{
			img: require("../../assets/images/partner/partner13.png"),
			name: "唐山银行"
		},
		{
			img: require("../../assets/images/partner/partner14.png"),
			name: "汇源"
		},
		{
			img: require("../../assets/images/partner/partner15.png"),
			name: "湖南兴旺集团"
		}
	];

	private created() {
		this.$emit("getChildrenData", this.parentData);
	}
}
